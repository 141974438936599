<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{
                      $t("welcome to scoring, transcript & certificate system ")
                    }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
              </v-row>

              <div style="margin-top: 20px"></div>

              <v-row>
                <v-col
                  sm="4"
                  cols="12"
                  v-for="(item, i) in listMajors"
                  :key="i"
                >
                  <v-card
                    color="#fccece"
                    dark
                    max-width="500"
                    class="mx-auto"
                    @click="
                      $router.push({
                        name: 'SessionStudent',
                        params: {
                          student_id: item.student_id,
                          name_en: item.name_en,
                          major_id: item.major_id,
                        },
                      })
                    "
                  >
                    <v-img
                      height="150"
                      src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fvcard-bg.jpg?alt=media&token=6b132c28-9bb9-4b08-bfbf-5c8bea5d2b97"
                    >
                      <v-card-title style="color: #ffff" class="text-h5">
                        {{ item.name_en }}
                      </v-card-title>

                      <router-link
                        style="text-decoration: none"
                        :to="
                          'studentt/session/' +
                          item.student_id +
                          '&' +
                          item.name_en +
                          '&' +
                          item.major_id
                        "
                        tag="button"
                      >
                      </router-link>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import store from "@/store";
import "vue-loading-overlay/dist/vue-loading.css";
import { getMajorByStudent } from "@schoolbase/web-client-lib";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data: () => ({
    listMajors: [],
    isLoading: false,
    token: store.getters.getToken,
    myLoading: false,
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
  }),
  computed: {},
  methods: {
    async loadMajor() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        let loggedUser = store.getters.getLoggedUser;
        let emailStudent = loggedUser.payload.user.email;
        const APIResponse = await getMajorByStudent(emailStudent, this.token);
        this.listMajors = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  components: {
    Loading,
  },
  mounted() {
    this.loadMajor();
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
}
</style>
